import React from "react"
import PropTypes from 'prop-types'
import Layout from "../../components/Layout"
import { graphql } from 'gatsby'
import SEO from '../../components/seo'
import CustomBreadcrumbs from '../../components/CustomBreadcrumbs'
import Image from "../../components/Image";

const ResourcePage = ({
    data,
    pageContext: {
      breadcrumb: { crumbs },
    },
}) => {
  const {resourcePage: page} = data;
  return (
      <Layout>
        <SEO title={page.metaTags.title} robots={page.metaTags.robots} />

        <CustomBreadcrumbs crumbs={crumbs}/>

        <h1>{page.title}</h1>

        { !!page.image && <Image imgName={page.image} /> }

        <div className="page-body" dangerouslySetInnerHTML={{__html: page.body}}/>
      </Layout>
  )
};

ResourcePage.propTypes = {
  data: PropTypes.shape({
    careerPage: PropTypes.object
  })
};

export default ResourcePage

export const pageQuery = graphql`
    query($id: String!) {
        resourcePage(nid: {eq: $id}) {
            type
            title
            metaTags {
                title
                robots
            }
            body
            image
        }
    }
`;
